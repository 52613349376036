import React from 'react';

const VinkIcon = ({ className, colour, width, height, text, logo }) => {
  return (
    <>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 500 208"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Logo AGAIN">
          {logo || (
            <g id="Group 14">
              <circle
                id="Ellipse 3"
                cx="120.901"
                cy="167.961"
                r="20.0704"
                transform="rotate(-180 120.901 167.961)"
                fill={colour ? colour : '#F2F2F2'}
              />
              <circle
                id="Ellipse 4"
                cx="55.0702"
                cy="60.3837"
                r="20.0704"
                transform="rotate(-180 55.0702 60.3837)"
                fill={colour ? colour : '#F2F2F2'}
              />
              <circle
                id="Ellipse 5"
                cx="185.93"
                cy="60.3837"
                r="20.0704"
                transform="rotate(-180 185.93 60.3837)"
                fill={colour ? colour : '#F2F2F2'}
              />
              <path
                id="Rectangle 12"
                d="M157.744 28.228L151.876 37.678C151.876 37.678 132.79 30.1905 120.012 30.2405C107.444 30.2897 88.7017 37.678 88.7017 37.678L83.4834 28.228C83.4834 28.228 105.299 19.1128 120.012 19.0011C135.181 18.8859 157.744 28.228 157.744 28.228Z"
                fill={colour ? colour : '#C5C5C5'}
              />
              <rect
                id="Rectangle 15"
                x="63.9014"
                y="86.6562"
                width="12.0423"
                height="71.5739"
                transform="rotate(-31 63.9014 86.6562)"
                fill={colour ? colour : '#F2F2F2'}
              />
              <rect
                id="Rectangle 16"
                x="167.398"
                y="80.4541"
                width="12.0423"
                height="71.5739"
                transform="rotate(31 167.398 80.4541)"
                fill={colour ? colour : '#F2F2F2'}
              />
            </g>
          )}
          {text || (
            <path
              id="Vink"
              d="M307.816 76.8L278.056 144H271.048L241.288 76.8H248.968L274.696 135.168L300.616 76.8H307.816ZM315.848 93.504H322.664V144H315.848V93.504ZM319.304 82.464C317.896 82.464 316.712 81.984 315.752 81.024C314.792 80.064 314.312 78.912 314.312 77.568C314.312 76.288 314.792 75.168 315.752 74.208C316.712 73.248 317.896 72.768 319.304 72.768C320.712 72.768 321.896 73.248 322.856 74.208C323.816 75.104 324.296 76.192 324.296 77.472C324.296 78.88 323.816 80.064 322.856 81.024C321.896 81.984 320.712 82.464 319.304 82.464ZM367.357 93.12C373.693 93.12 378.717 94.976 382.429 98.688C386.205 102.336 388.093 107.68 388.093 114.72V144H381.277V115.392C381.277 110.144 379.965 106.144 377.341 103.392C374.717 100.64 370.973 99.264 366.109 99.264C360.669 99.264 356.349 100.896 353.149 104.16C350.013 107.36 348.445 111.808 348.445 117.504V144H341.629V93.504H348.157V102.816C350.013 99.744 352.573 97.376 355.837 95.712C359.165 93.984 363.005 93.12 367.357 93.12ZM425.318 118.656L413.414 129.6V144H406.598V72.768H413.414V121.056L443.558 93.504H452.006L430.406 114.144L454.022 144H445.67L425.318 118.656Z"
              fill={colour ? colour : '#F2F2F2'}
            />
          )}
        </g>
      </svg>
    </>
  );
};

export default VinkIcon;
